export const advantages = [
  {
    icon: 'fas fa-heart',
    title: 'Trust',
    subtitle: [
      'Conduct ourselves with the highest level of integrity at all times',
      'Follow through on promises and commitments',
      'Maintain professionalism while communicating openly and honestly',
      'Accountable to our clients and to each of our staff and associates',
    ],
  },
  {
    icon: 'fas fa-hands',
    title: 'Caring',
    subtitle: [
      'Promote positive and respectful working relationships with our clients',
      'Strive to maintain balance between our personal and professional lives',
      'Contribute to our community',
      'Have fun and enjoy the journey',
    ],
  },
  {
    icon: 'far fa-star',
    title: 'Excellence',
    subtitle: [
      'Take pride in our people and the quality of our service',
      'Strive for excellence in all we do',
      'Seek out best practices',
      'Commit to ongoing professional training and learning',
    ],
  },
];
