import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid } from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';

const useStyles = makeStyles(() => ({
  image: {
    maxWidth: 800,
    borderRadius: 5,
  },
}));

const Story = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <Grid
        container
        justify="space-between"
        spacing={isMd ? 4 : 2}
        direction={isMd ? 'row' : 'column-reverse'}
      >
        <Grid
          item
          container
          alignItems="center"
          justify="flex-start"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <SectionHeader
            title="21+ Years of Success"
            subtitle="Helen Ge CPA Professional Corporation is a regional public accounting firm established in Ottawa in 1999. Over the past 21+ years, we proudly provide a wide range of high-quality professional services to our clients on accounting, taxation, financial and business matters. Our clientele includes corporations in a wide array of industries, not-for-profit organizations, and individuals in all walks of life, from local communities and far away. We offer holistic solutions tailored to each client’s needs. We deliver reliable and affordable services with personal attention and professional integrity."
            align="left"
            disableGutter
            subtitleProps={{
              color: 'textPrimary',
              variant: 'body1',
            }}
          />
        </Grid>
        <Grid
          item
          container
          justify={isMd ? 'flex-end' : 'flex-start'}
          alignItems="center"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <Image
            src={require('assets/images/about-2-sm.jpg')}
            alt="Our story"
            className={classes.image}
          />
        </Grid>
      </Grid>
      <Grid
        container
        justify="space-between"
        spacing={isMd ? 4 : 2}
        direction={isMd ? 'row' : 'column-reverse'}
      >
        <Grid
          item
          container
          justify={isMd ? 'flex-end' : 'flex-start'}
          alignItems="center"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <Image
            src={require('assets/images/about-1-sm.jpg')}
            alt="Our story"
            className={classes.image}
          />
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          justify="flex-start"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <SectionHeader
            title="A Steady Growing Firm with an Exceptional Professional Team"
            subtitle="At Helen Ge Professional Corporation, you connect with a team of more than 10 highly respected, experienced, passionate and friendly accounting and tax professionals. Since 1999, our firm has grown steadily in size, service locations, range of expertise and types of service offered. We established an office in Toronto in 2012. We expanded our service offerings to include tax planning, corporate restructuring, estate and succession planning, and virtual CFOs. Through investment in our people and technology, we continue to go above and beyond to meet our clients’ needs of today and tomorrow."
            align="left"
            disableGutter
            subtitleProps={{
              color: 'textPrimary',
              variant: 'body1',
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

Story.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Story;
