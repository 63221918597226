/**
 * Caution: Consider this file when using NextJS or GatsbyJS
 *
 * You may delete this file and its occurrences from the project filesystem if you are using react-scripts
 */
import React from 'react';
import About from 'views/About';
import Main from 'layouts/Main';
import { graphql } from 'gatsby';

const AboutPage = ({ data }) => {
  return (
    <div className="container">
      <Main>
        <About data={data} />
      </Main>
    </div>
  );
};

export const query = graphql`
  {
    profiles: allContentfulProfile(sort: { fields: order, order: ASC }) {
      nodes {
        order
        name
        title
        avatar {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        body {
          json
        }
      }
    }
    photos: allContentfulAlbum {
      nodes {
        title
        photo {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`;

export default AboutPage;
