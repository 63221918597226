import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Section, SectionAlternate } from 'components/organisms';
import { Hero, Story, Team } from './components';

import { advantages } from './data';
import Advantages from './components/Advantages';
import PromoSwiper from './components/PromoSwiper';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  sectionPartners: {
    boxShadow: '0 5px 20px 0 rgba(90, 202, 157, 0.05)',
    '& .section-alternate__content': {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
  },
}));

const About = ({ data }) => {
  const classes = useStyles();

  return (
    <Box mt={8} className={classes.root}>
      <Hero />
      <Section>
        <Story />
      </Section>
      <SectionAlternate>
        <Advantages data={advantages} />
      </SectionAlternate>
      <Section>
        <Team data={data} />
      </Section>
      <Section>
        <PromoSwiper data={data} />
      </Section>
    </Box>
  );
};

export default About;
